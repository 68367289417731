<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="about_client" service="refCredit" backlink="/refinance-credit/members" />
      <div class="content illustration illustration_form">
        <div class="content__container">
          <Clients service="refCredit" :clients="clients" nextlink="/refinance-credit/amount-credits" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Clients from '@/components/service/Clients.vue'

export default {
  name: 'Credit-client',
  props: { clients: { type: Number } },
  components: { Backlink, Clients },
}
</script>
